<template>
    <main :class="{'mt-3': alert.show}">
        <div class="container">
            <template v-if="alert.show">
                <div class="alert alert-warning" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{alert.message}}</p>
                </div>
            </template>
            <template v-else-if="!loading">
                <div class="mt-3 mb-3">
                    <div class="logo">
                        <a href="/">
                            <img :src="logo" width="200">
                        </a>
                    </div><!-- /logo -->
                    <div v-show="bet.situation != 1" class="alert text-center mb-3" :class="{'alert-success': bet.situation == 2, 'alert-danger': bet.situation == 3, 'alert-secondary': bet.situation == 4}">
                        <template v-if="bet.situation == 2">
                            <i class="fas fa-trophy mr-2"></i>Aposta premiada
                        </template>
                        <template v-else-if="bet.situation == 3">
                            <i class="fas fa-sad-tear mr-2"></i>Aposta perdida
                        </template>
                        <template v-else-if="bet.situation == 4">
                            <i class="fas fa-ban mr-2"></i>Aposta cancelada
                        </template>
                        <template v-else>Sem informações</template>
                    </div>
                    <div class="row">
                        <div class="col text-white text-truncate" :class="{'text-center': platform == 'desktop'}">Código</div>
                        <div class="col text-white text-truncate text-center">Horário</div>
                        <div class="col text-white text-truncate" :class="{'text-center': platform == 'desktop', 'text-right': platform == 'mobile'}">Vendedor</div>
                    </div><!-- /row -->
                    <div class="row">
                        <div class="col text-warning text-truncate text-uppercase" :class="{'text-center': platform == 'desktop'}">{{bet.code}}</div>
                        <div class="col text-warning text-truncate text-center">{{bet.time_table}}</div>
                        <div class="col text-warning text-truncate" :class="{'text-center': platform == 'desktop', 'text-right': platform == 'mobile'}">{{bet.salesman}}</div>
                    </div><!-- /row -->
                    <div class="w-100 border-top border-dark mt-3 mb-3"></div>
                    <div class="row">
                        <div class="col text-white text-truncate" :class="{'text-center': platform == 'desktop'}">Valor</div>
                        <div class="col text-white text-truncate text-center">Prêmio</div>
                        <div class="col text-white text-truncate" :class="{'text-center': platform == 'desktop', 'text-right': platform == 'mobile'}">Apostador</div>
                    </div><!-- /row -->
                    <div class="row">
                        <div class="col text-warning text-truncate" :class="{'text-center': platform == 'desktop'}">{{bet.value | formatCoin}}</div>
                        <div class="col text-warning text-truncate text-center">{{bet.premium | formatCoin}}</div>
                        <div class="col text-warning text-truncate" :class="{'text-center': platform == 'desktop', 'text-right': platform == 'mobile'}">{{bet.punter}}</div>
                    </div><!-- /row -->
                </div><!-- /mt-3 -->
                <template v-if="bet.situation == 1">
                    <div v-for="(item, index) in orderBySchedule(hunches)" :key="index" class="p-3 bg-dark mb-3 rounded">
                        <div class="row">
                            <div class="col text-truncate">
                                <div class="d-flex align-items-center flex-column">
                                    <div>
                                        <img class="shield" :src="`${image_server}/s/${imageShield(item.team_home)}`" @error="imageError($event, '/images/shield_default.png')" :alt="item.team_home" width="50" height="50" border="0">
                                    </div>
                                    <div class="text-white text-truncate text-center w-100">{{item.team_home}}</div>
                                </div><!-- /d-flex -->
                            </div><!-- /col -->
                            <div class="col">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div class="d-flex justify-content-center align-items-center align-self-center">
                                        <div class="text-white">{{item.info.score['90'].home}}</div>
                                        <div class="text-white ml-3 mr-3">
                                            <i class="fal fa-times"></i>
                                        </div>
                                        <div class="text-white">{{item.info.score['90'].away}}</div>
                                    </div>
                                    <div class="w-100 text-center">
                                        <div class="badge badge-danger w-100 text-truncate mt-1">
                                            <template v-if="!item.live && current_time < item.schedule">
                                                <span>{{item.schedule | formatDateDDMMHHMM}}</span>
                                            </template>
                                            <template v-else-if="!item.live && current_time > item.schedule">
                                                <template v-if="item.situation == 1 || item.situation == 2 || item.situation == 3 || item.situation == 4">
                                                    <span>{{item.situation == 1 || item.situation == 2 ? 'Encerrado' : findSituation(item.situation)}}</span>
                                                </template>
                                                <template v-else>
                                                    <span>Sem transmissão</span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span>{{item.info.period == 'Half Time' ? 'INTERVALO' : item.info.time}}</span>
                                                <span v-show="item.info.period != 'Half Time'" class="blink-animation">'</span>
                                            </template>
                                        </div>
                                    </div>
                                </div><!-- /d-flex -->
                            </div><!-- /col -->
                            <div class="col text-truncate">
                                <div class="d-flex align-items-center flex-column">
                                    <div>
                                        <img class="shield" :src="`${image_server}/s/${imageShield(item.team_away)}`" @error="imageError($event, '/images/shield_default.png')" :alt="item.team_away" width="50" height="50" border="0">
                                    </div>
                                    <div class="text-white text-truncate text-center w-100">{{item.team_away}}</div>
                                </div><!-- /d-flex -->
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="row mt-3 pt-3 border-top border-secondary">
                            <template v-if="!item.live && item.situation == 0 && current_time < item.schedule">
                                <div class="alert alert-info w-100 ml-3 mr-3 mb-0 text-center">Essa partida ainda não iniciou!</div>
                            </template>
                            <template v-else-if="!item.live && item.situation == 0 && current_time > item.schedule">
                                <div class="alert alert-info w-100 ml-3 mr-3 mb-0 text-center">Essa partida está fora da nossa cobertura de transmissão!</div>
                            </template>
                            <template v-else>
                                <div class="w-100 text-truncate text-center text-info border-secondary mb-3" :class="{'border-bottom pb-3': !item.transmissao_active}">
                                    <small>{{item.league}}</small>
                                    <div class="widget-field-soccer2 mt-3" v-if="item.live && item.transmissao_id && item.transmissao_active">
                                        <iframe :src="`https://live-stream.betsnow.net/widget/soccer/${item.transmissao_id}`" frameborder="0" scrolling="no" allowtransparency="true" allow="autoplay"></iframe>
                                    </div>
                                </div><!-- /w-100 -->
                                <div class="col text-left text-truncate" :class="{'mt-1': item.transmissao_id}">
                                    <span class="text-white">Situação</span>    
                                </div><!-- /col -->
                                <div class="col text-center text-white" v-if="item.live && item.transmissao_id">
                                    <button class="btn btn-sm btn-block btn-info" @click="showTv(item)">
                                        <template v-if="platform == 'mobile'">
                                            <i class="fas fa-tv"></i>
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-tv mr-2"></i>{{item.transmissao_active == true ? 'Fechar' : 'Assistir'}}
                                        </template>
                                    </button>
                                </div>
                                <div class="col text-right text-white text-truncate" :class="{'mt-1': item.transmissao_id}">
                                    <span :class="{'text-success blink-animation': item.situation == 'Ganhando', 'text-success': item.situation == 1, 'text-danger blink-animation': item.situation == 'Perdendo', 'text-danger': item.situation == 2, 'text-warning': item.situation == 'Aguardando' || item.situation == 4, 'text-primary': item.situation == 3, 'text-white': item.situation == 5}">
                                        {{item.situation == 'Ganhando' || item.situation == 'Perdendo' ? item.situation : findSituation(item.situation)}}    
                                    </span> 
                                </div><!-- /col -->
                            </template>
                        </div><!-- /row -->
                        <div class="row m-0 mt-3">
                            <div class="col-12 text-center pt-1 pb-1 bg-secondary rounded">
                                <span class="text-white">{{item.market}}</span> 
                            </div><!-- /col -->
                        </div><!-- /row -->
                    </div><!-- /p-3 -->
                </template>
                <template v-else>
                    <div class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr>
                        <p class="mb-0">Não é mais possível acompanhar <strong>ao vivo</strong> essa aposta. <a :href="`/ticket/${bet.code}`">Clique aqui</a> para ver a situação da aposta.</p>
                    </div>                    
                </template>
            </template>
            <div class="alert text-center text-white pt-0 mb-0" role="alert">
                <h4 class="alert-heading">Aviso importante</h4>
                <p class="mb-0 mt-3" v-html="message" :class="{'text-justify': platform == 'mobile'}"></p>
            </div>
            <div class="w-100 mt-2 mb-3 text-center">
                <a class="btn btn-block btn-danger text-white" :href="service_link">
                    <i class="fas fa-headset mr-2"></i>Atendimento
                </a>
            </div>
            <footer class="footer pb-3">
                <div class="row w-100">
                    <div class="col text-right pr-0">
                        <small class="text-white">Desenvolvido por</small>
                    </div>
                    <div class="col text-left pl-0">
                        <a href="https://betsnow.net">
                            <img src="https://demo.betsnow.net/images/logo.png" height="30" alt="BetsNOW">
                        </a>
                    </div>
                </div>
            </footer>
        </div>
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import io from 'socket.io-client'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

let socket = io('wss://live-ticket.betsnow.net', {
    secure: true,
    autoConnect: false,
    rememberUpgrade: true,
    rejectUnauthorized: false,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    transports: ['websocket']
});

export default {
    data() {
        return {
            bet: [],
            events: [],
            hunches: {},
            changed: false,
            loading: false,
            alert: {
                show: false,
                message: ''
            },
            status: { 
                losing: 'Perdendo',
                winning: 'Ganhando',
                waiting: 'Aguardando'
            },
            current_time: moment().tz('America/Recife').format(),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            },
            message: 'Embora todos os esforços sejam feitos para garantir que as informações exibidas no local com relação a um evento sejam corretas, elas devem ser usadas apenas como um guia. <br><br> No caso de qualquer informação específica (pontuação, hora do jogo, etc.) estar incorreta, não assumimos qualquer responsabilidade por isso. <br><br> Consulte nossas regras de apostas para obter informações sobre como resolvemos mercados individuais. <br><br> Em geral, os resultados ao vivo são atrasados ​​alguns minutos em relação aos jogos ao vivo no local do evento.'
        }
    },
    components: {
        Loading
    },
    methods: {
        liveStream() {

            const self = this;

            socket.open();

            // socket.on('connect_failed', () => {
            //     self.alert.show = true;
            //     self.alert.message = 'Não foi possível conectar ao servidor!';
            // });

            // socket.on('connect_error', () => {
            //     self.alert.show = true;
            //     self.alert.message = 'Ocorreu um erro na conexão com o servidor!';
            // });

            socket.on('events', (snapshot) => {

                try {

                    const {
                        result, 
                        total, 
                        events
                    } = JSON.parse(snapshot);

                    self.events = events;

                    if (result == 'nothing_found' || total == 0) {
                        self.alert.show = true;
                        self.alert.message = 'Nenhum evento disponível!';
                    } 
                    else if (!Array.isArray(events))
                    {
                        self.alert.show = true;
                        self.alert.message = 'Nenhum evento disponível!';
                    }
                    else 
                    {
                        for (var item of events) {

                            if (self.hunches.hasOwnProperty(item.id)) {

                                let event = self.hunches[item.id];
                                let [home_90, away_90] = item.score.split('-');
                                let [home_pt, away_pt] = item.score_ft.split('-');

                                event.live = true;
                                event.info.time = item.timer;
                                event.info.period = item.period;
                                event.info.score['90'].home = home_90;
                                event.info.score['90'].away = away_90;
                                event.info.score['pt'].home = home_pt;
                                event.info.score['pt'].away = away_pt;
                                event.situation = self.situation(event);
                            }
                        }

                        self.checkSituation();
                    }
                } 
                catch(e) 
                {
                    self.alert.show = true;
                    self.alert.message = e.message;
                }
            });
        },
        ticketByCode(code) {

            const self = this;

            if (!code) return;

            self.code = code;
            self.loading = true;
            self.alert.show = false;

            api.get(`bet/anonymous/${code}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.bet = response.data.bets[0];
                        self.viewBet(response.data.id);
                    break;
                    case 'nothing_found':
                        self.loading = false;
                        self.alert.show = true;
                        self.alert.message = 'Não foi possível encontrar a aposta. Por favor, verifique o código e tente novamente!';
                    break;
                    default:
                        self.loading = false;
                        self.alert.show = true;
                        self.alert.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.loading = false;
                    self.alert.show = true;
                    self.alert.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.loading = false;
                    self.alert.show = true;
                    self.alert.message = self.network_erros[408]['message']
                }
            });
        },
        viewBet(id) {

            const self = this;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountDatabase(response.data.hunches);
                    break;
                    default:
                        self.alert.show = true;
                        self.alert.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.alert.show = true;
                    self.alert.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.alert.show = true;
                    self.alert.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        mountDatabase(hunches) {

            for (let key in hunches) {
                let hunch = hunches[key];
                let [score_home_90, score_away_90] = hunch.scoreboard.split('-');

                this.$set(this.hunches, hunch.servidor_id, {
                    id: hunch.servidor_id,
                    transmissao_active: false,
                    transmissao_id: hunch.transmissao_id,
                    live: false,
                    info: {
                        time: 0,
                        period: 0,
                        score: {
                            '90': {
                                home: score_home_90,
                                away: score_away_90
                            },
                            'pt': {
                                home: 0,
                                away: 0
                            }
                        }
                    },
                    odd: hunch.odd,
                    sport: hunch.sport,
                    score: hunch.score,
                    option: hunch.option,
                    market: hunch.market,
                    league: hunch.league,
                    schedule: hunch.schedule,
                    situation: hunch.situation,
                    team_home: hunch.team_home,
                    team_away: hunch.team_away,
                    accumulated: hunch.accumulated
                });
            }

            // this.getCache();
            this.liveStream();
        },
        situation(hunch) {

            let situation = this.status.waiting;
            let score_home_90 = parseInt(hunch.info.score['90'].home);
            let score_away_90 = parseInt(hunch.info.score['90'].away);
            let score_home_pt = parseInt(hunch.info.score['pt'].home);
            let score_away_pt = parseInt(hunch.info.score['pt'].away);
            let score_home_st = parseInt(score_home_90 - score_home_pt);
            let score_away_st = parseInt(score_away_90 - score_away_pt);

            switch(hunch.option) {

                /* OPÇÂO CASA - TEMPO COMPLETO */
                case 1:
                    if (score_home_90 > score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO EMPATE - TEMPO COMPLETO */
                case 2:
                    if (score_home_90 == score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO FORA - TEMPO COMPLETO */
                case 3:
                    if (score_home_90 < score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO DUPLA CASA - TEMPO COMPLETO */
                case 4:
                    if (score_home_90 >= score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO DUPLA FORA - TEMPO COMPLETO */
                case 5:
                    if (score_away_90 >= score_home_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO DUPLA CASA OU FORA - TEMPO COMPLETO */
                case 6:
                    if (score_home_90 > score_away_90 || score_away_90 > score_home_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO GOL E MEIO CASA - TEMPO COMPLETO */
                case 7:
                    if (score_home_90 >= 2) {
                        
                        if ((score_home_90 - score_away_90) >= 2) {
                            situation = this.status.winning;
                        } else {
                            situation = this.status.losing;
                        }
                    } 
                    else 
                    {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO GOL E MEIO FORA - TEMPO COMPLETO */
                case 8:
                    if (score_away_90 >= 2) {
                        
                        if ((score_away_90 - score_home_90) >= 2) {
                            situation = this.status.winning;
                        } else {
                            situation = this.status.losing;
                        }
                    } 
                    else 
                    {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO 2 GOLS E MEIO CASA - TEMPO COMPLETO */
                case 9:
                    if (score_home_90 >= 3) {
                        
                        if ((score_home_90 - score_away_90) >= 3) {
                            situation = this.status.winning;
                        } else {
                            situation = this.status.losing;
                        }
                    } 
                    else 
                    {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO 2 GOLS E MEIO FORA - TEMPO COMPLETO */
                case 10:
                    if (score_away_90 >= 3) {
                        
                        if ((score_away_90 - score_home_90) >= 3) {
                            situation = this.status.winning;
                        } else {
                            situation = this.status.losing;
                        }
                    } 
                    else 
                    {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO AMBAS MARCAM SIM - TEMPO COMPLETO */
                case 11:
                    if (score_home_90 >= 1 && score_away_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO AMBAS MARCAM NÃO - TEMPO COMPLETO */
                case 12:
                    if (score_home_90 == 0 && score_away_90 == 0) {
                        situation = this.status.winning;
                    } else if (score_home_90 >= 1 && score_away_90 == 0) {
                        situation = this.status.winning;
                    } else if (score_home_90 == 0 && score_away_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ACIMA 0.5 NA PARTIDA - TEMPO COMPLETO */
                case 13:
                    if ((score_home_90 + score_away_90) >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ACIMA 1.5 NA PARTIDA - TEMPO COMPLETO */
                case 14:
                    if ((score_home_90 + score_away_90) >= 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ACIMA 2.5 NA PARTIDA - TEMPO COMPLETO */
                case 15:
                    if ((score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ACIMA 3.5 NA PARTIDA - TEMPO COMPLETO */
                case 16:
                    if ((score_home_90 + score_away_90) >= 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ACIMA 4.5 NA PARTIDA - TEMPO COMPLETO */
                case 17:
                    if ((score_home_90 + score_away_90) >= 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ACIMA 5.5 NA PARTIDA - TEMPO COMPLETO */
                case 18:
                    if ((score_home_90 + score_away_90) >= 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA VENCE, FORA MARCA - TEMPO COMPLETO */
                case 19:
                    if (score_home_90 > score_away_90 && score_away_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO FORA VENCE, CASA MARCA - TEMPO COMPLETO */
                case 20:
                    if (score_away_90 > score_home_90 && score_home_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO EMPATE COM GOLS - TEMPO COMPLETO */
                case 21:
                    if (score_home_90 == score_away_90 && score_home_90 >= 1 && score_away_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO EMPATE SEM GOLS - TEMPO COMPLETO */
                case 22:
                    if (score_home_90 == score_away_90 && score_home_90 == 0 && score_away_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA VENCE DE ZERO - TEMPO COMPLETO */
                case 23:
                    if (score_home_90 > score_away_90 && score_away_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO FORA VENCE DE ZERO - TEMPO COMPLETO */
                case 24:
                    if (score_away_90 > score_home_90 && score_home_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO RESULTADO PAR - TEMPO COMPLETO */
                case 25:
                    if ((score_home_90 + score_away_90) % 2 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO RESULTADO ÍMPAR - TEMPO COMPLETO */
                case 26:
                    if ((score_home_90 + score_away_90) % 2 != 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO PLACAR EXATO - TEMPO COMPLETO */
                case 27:
                    if (`${score_home_90}-${score_away_90}` == hunch.score) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - CASA/CASA - TEMPO COMPLETO */
                case 28:
                    if (score_home_pt > score_away_pt && score_home_90 > score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - CASA/EMPATE - TEMPO COMPLETO */
                case 29:
                    if (score_home_pt > score_away_pt && score_home_90 == score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - CASA/FORA - TEMPO COMPLETO */
                case 30:
                    if (score_home_pt > score_away_pt && score_away_90 > score_home_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - EMPATE/CASA - TEMPO COMPLETO */
                case 31:
                    if (score_home_pt == score_away_pt && score_home_90 > score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - EMPATE/EMPATE - TEMPO COMPLETO */
                case 32:
                    if (score_home_pt == score_away_pt && score_home_90 == score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - EMPATE/FORA - TEMPO COMPLETO */
                case 33:
                    if (score_home_pt == score_away_pt && score_away_90 > score_home_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - FORA/CASA - TEMPO COMPLETO */
                case 34:
                    if (score_away_pt > score_home_pt && score_home_90 > score_away_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - FORA/EMPATE - TEMPO COMPLETO */
                case 35:
                    if (score_away_pt > score_home_pt && score_away_90 == score_home_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO INTERVALO/FIM DO JOGO - FORA/EMPATE - TEMPO COMPLETO */
                case 36:
                    if (score_away_pt > score_home_pt && score_away_90 > score_home_90) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & +1.5 - TEMPO COMPLETO */
                case 37:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) >= 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & -1.5 - TEMPO COMPLETO */
                case 38:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) < 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & +2.5 - TEMPO COMPLETO */
                case 39:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & -2.5 - TEMPO COMPLETO */
                case 40:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & +3.5 - TEMPO COMPLETO */
                case 41:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) >= 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & -3.5 - TEMPO COMPLETO */
                case 42:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) < 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & +4.5 - TEMPO COMPLETO */
                case 43:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) >= 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & -4.5 - TEMPO COMPLETO */
                case 44:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) < 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;
                
                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & +5.5 - TEMPO COMPLETO */
                case 45:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) >= 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - CASA & -5.5 - TEMPO COMPLETO */
                case 46:
                    if (score_home_90 > score_away_90 && (score_home_90 + score_away_90) < 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & +1.5 - TEMPO COMPLETO */
                case 47:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) >= 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & -1.5 - TEMPO COMPLETO */
                case 48:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) < 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & +2.5 - TEMPO COMPLETO */
                case 49:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & -2.5 - TEMPO COMPLETO */
                case 50:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & +3.5 - TEMPO COMPLETO */
                case 51:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) >= 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & -3.5 - TEMPO COMPLETO */
                case 52:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) < 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & +4.5 - TEMPO COMPLETO */
                case 53:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) >= 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & -4.5 - TEMPO COMPLETO */
                case 54:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) < 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;
                
                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & +5.5 - TEMPO COMPLETO */
                case 55:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) >= 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - EMPATE & -5.5 - TEMPO COMPLETO */
                case 56:
                    if (score_home_90 == score_away_90 && (score_home_90 + score_away_90) < 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & +1.5 - TEMPO COMPLETO */
                case 57:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) >= 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & -1.5 - TEMPO COMPLETO */
                case 58:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) < 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & +2.5 - TEMPO COMPLETO */
                case 59:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & -2.5 - TEMPO COMPLETO */
                case 60:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & +3.5 - TEMPO COMPLETO */
                case 61:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) >= 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & -3.5 - TEMPO COMPLETO */
                case 62:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) < 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & +4.5 - TEMPO COMPLETO */
                case 63:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) >= 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & -4.5 - TEMPO COMPLETO */
                case 64:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) < 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;
                
                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & +5.5 - TEMPO COMPLETO */
                case 65:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) >= 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO VENCEDOR / TOTAL DE GOLS - FORA & -5.5 - TEMPO COMPLETO */
                case 66:
                    if (score_away_90 > score_home_90 && (score_home_90 + score_away_90) < 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS / AMBAS MARCAM: SIM & +2.5 - TEMPO COMPLETO */
                case 67:
                    if (score_home_90 >= 1 && score_away_90 >= 1 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS / AMBAS MARCAM: NÃO & +2.5 - TEMPO COMPLETO */
                case 68:
                    if (score_home_90 == 0 && score_away_90 == 0 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else if (score_home_90 >= 1 && score_away_90 == 0 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else if (score_home_90 == 0 && score_away_90 >= 1 && (score_home_90 + score_away_90) >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS / AMBAS MARCAM: SIM & -2.5 - TEMPO COMPLETO */
                case 69:
                    if (score_home_90 >= 1 && score_away_90 >= 1 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS / AMBAS MARCAM: NÃO & -2.5 - TEMPO COMPLETO */
                case 70:
                    if (score_home_90 == 0 && score_away_90 == 0 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else if (score_home_90 >= 1 && score_away_90 == 0 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else if (score_home_90 == 0 && score_away_90 >= 1 && (score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 0 GOL - TEMPO COMPLETO */
                case 71:
                    if ((score_home_90 + score_away_90) == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 1 GOL - TEMPO COMPLETO */
                case 72:
                    if ((score_home_90 + score_away_90) == 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 2 GOLS - TEMPO COMPLETO */
                case 73:
                    if ((score_home_90 + score_away_90) == 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 3 GOLS - TEMPO COMPLETO */
                case 74:
                    if ((score_home_90 + score_away_90) == 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 4 GOLS - TEMPO COMPLETO */
                case 75:
                    if ((score_home_90 + score_away_90) == 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 5 GOLS - TEMPO COMPLETO */
                case 76:
                    if ((score_home_90 + score_away_90) == 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: 6 GOLS - TEMPO COMPLETO */
                case 77:
                    if ((score_home_90 + score_away_90) == 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO TOTAL DE GOLS EXATOS NA PARTIDA: +7 GOLS - TEMPO COMPLETO */
                case 78:
                    if ((score_home_90 + score_away_90) >= 7) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* AMBAS MARCAM - 1º E 2º TEMPO - SIM/SIM */
                case 79:
                    if (score_home_pt >= 1 && score_away_pt >= 1 && score_home_st >= 1 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* AMBAS MARCAM - 1º E 2º TEMPO - SIM/NÃO */
                case 80:
                    if (score_home_pt >= 1 && score_away_pt >= 1 && score_home_st == 0 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt >= 1 && score_away_pt >= 1 && score_home_st >= 1 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt >= 1 && score_away_pt >= 1 && score_home_st == 0 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* AMBAS MARCAM - 1º E 2º TEMPO - NÃO/SIM */
                case 81:
                    if (score_home_pt == 0 && score_away_pt == 0 && score_home_st >= 1 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else if (score_home_pt >= 1 && score_away_pt == 0 && score_home_st >= 1 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else if (score_home_pt == 0 && score_away_pt >= 1 && score_home_st >= 1 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;
                
                /* AMBAS MARCAM - 1º E 2º TEMPO - NÃO/NÃO */
                case 82:
                    if (score_home_pt == 0 && score_away_pt == 0 && score_home_st == 0 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt >= 1 && score_away_pt == 0 && score_home_st >= 1 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt >= 1 && score_away_pt == 0 && score_home_st == 0 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt >= 1 && score_away_pt == 0 && score_home_st == 0 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else if (score_home_pt == 0 && score_away_pt == 0 && score_home_st >= 1 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt == 0 && score_away_pt >= 1 && score_home_st == 0 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else if (score_home_pt == 0 && score_away_pt >= 1 && score_home_st == 0 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt == 0 && score_away_pt >= 1 && score_home_st >= 1 && score_away_st == 0) {
                        situation = this.status.winning;
                    } else if (score_home_pt == 0 && score_away_pt == 0 && score_home_st == 0 && score_away_st >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TEMPO COM MAIS GOL - 1º TEMPO */
                case 83:
                    if ((score_home_pt + score_away_pt) > (score_home_st + score_away_st)) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TEMPO COM MAIS GOL - 2º TEMPO */
                case 84:
                    if ((score_home_st + score_away_st) > (score_home_pt + score_away_pt)) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TEMPO COM MAIS GOL - EMPATE */
                case 85:
                    if ((score_home_pt + score_away_pt) == (score_home_st + score_away_st)) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DA CASA - 0 */
                case 86:
                    if (score_home_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DA CASA - 1 */
                case 87:
                    if (score_home_90 == 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DA CASA - 2 */
                case 88:
                    if (score_home_90 == 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DA CASA - +3 */
                case 89:
                    if (score_home_90 >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DE FORA - 0 */
                case 90:
                    if (score_away_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DE FORA - 1 */
                case 91:
                    if (score_away_90 == 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DE FORA - 2 */
                case 92:
                    if (score_away_90 == 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* TOTAL DE GOLS EXATOS - TIME DE FORA - +3 */
                case 93:
                    if (score_away_90 >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO PAR - TIME DA CASA */
                case 94:
                    if (score_home_90 % 2 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ÍMPAR - TIME DA CASA */
                case 95:
                    if (score_home_90 % 2 != 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO PAR - TIME DE FORA */
                case 96:
                    if (score_away_90 % 2 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ÍMPAR - TIME DE FORA */
                case 97:
                    if (score_away_90 % 2 != 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ACIMA 0.5 */
                case 103:
                    if (score_home_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ACIMA 1.5 */
                case 104:
                    if (score_home_90 >= 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ACIMA 2.5 */
                case 105:
                    if (score_home_90 >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ACIMA 3.5 */
                case 106:
                    if (score_home_90 >= 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ACIMA 4.5 */
                case 107:
                    if (score_home_90 >= 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ABAIXO 0.5 */
                case 108:
                    if (score_home_90 < 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ABAIXO 1.5 */
                case 109:
                    if (score_home_90 < 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ABAIXO 2.5 */
                case 110:
                    if (score_home_90 < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ABAIXO 3.5 */
                case 111:
                    if (score_home_90 < 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - TOTAL DE GOLS - ABAIXO 4.5 */
                case 112:
                    if (score_home_90 < 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ACIMA 0.5 */
                case 113:
                    if (score_away_90 >= 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ACIMA 1.5 */
                case 114:
                    if (score_away_90 >= 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ACIMA 2.5 */
                case 115:
                    if (score_away_90 >= 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ACIMA 3.5 */
                case 116:
                    if (score_away_90 >= 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ACIMA 4.5 */
                case 117:
                    if (score_away_90 >= 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ABAIXO 0.5 */
                case 118:
                    if (score_away_90 < 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ABAIXO 1.5 */
                case 119:
                    if (score_away_90 < 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ABAIXO 2.5 */
                case 120:
                    if (score_away_90 < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ABAIXO 3.5 */
                case 121:
                    if (score_away_90 < 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO FORA - TOTAL DE GOLS - ABAIXO 4.5 */
                case 122:
                    if (score_away_90 < 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO CASA - PRIMEIRO TEMPO */
                case 129:
                    if (score_home_pt > score_away_pt) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO EMPATE - PRIMEIRO TEMPO */
                case 130:
                    if (score_home_pt == score_away_pt) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO FORA - PRIMEIRO TEMPO */
                case 131:
                    if (score_home_pt < score_away_pt) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO DUPLA CASA - PRIMEIRO TEMPO */
                case 132:
                    if (score_home_pt >= score_away_pt) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO DUPLA FORA - PRIMEIRO TEMPO */
                case 133:
                    if (score_away_pt >= score_home_pt) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO DUPLA CASA OU FORA - PRIMEIRO TEMPO */
                case 134:
                    if (score_home_pt > score_away_pt || score_away_pt > score_home_pt) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO GOL E MEIO CASA - PRIMEIRO TEMPO */
                case 135:
                    if (score_home_pt >= 2) {
                        
                        if ((score_home_pt - score_away_pt) >= 2) {
                            situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO GOL E MEIO FORA - PRIMEIRO TEMPO */
                case 136:
                    if (score_away_pt >= 2) {
                        
                        if ((score_away_pt - score_home_pt) >= 2) {
                            situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO 2 GOLS E MEIO CASA - PRIMEIRO TEMPO */
                case 137:
                    if (score_home_pt >= 3) {
                        
                        if ((score_home_pt - score_away_pt) >= 3) {
                            situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO 2 GOLS E MEIO FORA - PRIMEIRO TEMPO */
                case 138:
                    if (score_away_pt >= 3) {
                        
                        if ((score_away_pt - score_home_pt) >= 3) {
                            situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO AMBAS MARCAM SIM - PRIMEIRO TEMPO */
                case 139:
                    if (score_home_pt >= 1 && score_away_pt >= 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO AMBAS MARCAM NÃO - PRIMEIRO TEMPO */
                case 140:
                    if (score_home_pt == 0 && score_away_pt == 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else if (score_home_pt >= 1 && score_away_pt == 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else if (score_home_pt == 0 && score_away_pt >= 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 0.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 141:
                    if ((score_home_pt + score_away_pt) >= 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 1.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 142:
                    if ((score_home_pt + score_away_pt) >= 2) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 2.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 143:
                    if ((score_home_pt + score_away_pt) >= 3) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 3.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 144:
                    if ((score_home_pt + score_away_pt) >= 4) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 4.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 145:
                    if ((score_home_pt + score_away_pt) >= 5) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 5.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 146:
                    if ((score_home_pt + score_away_pt) >= 6) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO CASA VENCE, FORA MARCA - PRIMEIRO TEMPO */
                case 147:
                    if (score_home_pt > score_away_pt && score_away_pt >= 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO FORA VENCE, CASA MARCA - PRIMEIRO TEMPO */
                case 148:
                    if (score_away_pt > score_home_pt && score_home_pt >= 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO EMPATE COM GOLS - PRIMEIRO TEMPO */
                case 149:
                    if (score_home_pt == score_away_pt && score_home_pt >= 1 && score_away_pt >= 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO EMPATE SEM GOLS - PRIMEIRO TEMPO */
                case 150:
                    if (score_home_pt == score_away_pt && score_home_pt == 0 && score_away_pt == 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO CASA VENCE DE ZERO - PRIMEIRO TEMPO */
                case 151:
                    if (score_home_pt > score_away_pt && score_away_pt == 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO FORA VENCE DE ZERO - PRIMEIRO TEMPO */
                case 152:
                    if (score_away_pt > score_home_pt && score_home_pt == 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO RESULTADO PAR - PRIMEIRO TEMPO */
                case 153:
                    if ((score_home_pt + score_away_pt) % 2 == 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO RESULTADO ÍMPAR - PRIMEIRO TEMPO */
                case 154:
                    if ((score_home_pt + score_away_pt) % 2 != 0) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO PLACAR EXATO - PRIMEIRO TEMPO */
                case 155:
                    if (`${score_home_pt}-${score_away_pt}` == hunch.score) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO CASA - SEGUNDO TEMPO */
                case 156:
                    if (score_home_st > score_away_st) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO EMPATE - SEGUNDO TEMPO */
                case 157:
                    if (score_home_st == score_away_st) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO FORA - SEGUNDO TEMPO */
                case 158:
                    if (score_home_st < score_away_st) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO DUPLA CASA - SEGUNDO TEMPO */
                case 159:
                    if (score_home_st >= score_away_st) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO DUPLA FORA - SEGUNDO TEMPO */
                case 160:
                    if (score_away_st >= score_home_st) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO DUPLA CASA OU FORA - SEGUNDO TEMPO */
                case 161:
                    if (score_home_st > score_away_st || score_away_st > score_home_st) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO GOL E MEIO CASA - SEGUNDO TEMPO */
                case 162:
                    if (score_home_st >= 2) {
                        
                        if ((score_home_st - score_away_st) >= 2) {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO GOL E MEIO FORA - SEGUNDO TEMPO */
                case 163:
                    if (score_away_st >= 2) {
                        
                        if ((score_away_st - score_home_st) >= 2) {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO 2 GOLS E MEIO CASA - SEGUNDO TEMPO */
                case 164:
                    if (score_home_st >= 3) {
                        
                        if ((score_home_st - score_away_st) >= 3) {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO 2 GOLS E MEIO FORA - SEGUNDO TEMPO */
                case 165:
                    if (score_away_st >= 3) {
                        
                        if ((score_away_st - score_home_st) >= 3) {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                        } else {
                            situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                        }
                    } 
                    else 
                    {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO AMBAS MARCAM SIM - SEGUNDO TEMPO */
                case 166:
                    if (score_home_st >= 1 && score_away_st >= 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO AMBAS MARCAM NÃO - SEGUNDO TEMPO */
                case 167:
                    if (score_home_st == 0 && score_away_st == 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else if (score_home_st >= 1 && score_away_st == 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else if (score_home_st == 0 && score_away_st >= 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 0.5 NA PARTIDA - SEGUNDO TEMPO */
                case 168:
                    if ((score_home_st + score_away_st) >= 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 1.5 NA PARTIDA - SEGUNDO TEMPO */
                case 169:
                    if ((score_home_st + score_away_st) >= 2) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 2.5 NA PARTIDA - SEGUNDO TEMPO */
                case 170:
                    if ((score_home_st + score_away_st) >= 3) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 3.5 NA PARTIDA - SEGUNDO TEMPO */
                case 171:
                    if ((score_home_st + score_away_st) >= 4) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 4.5 NA PARTIDA - SEGUNDO TEMPO */
                case 172:
                    if ((score_home_st + score_away_st) >= 5) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ACIMA 5.5 NA PARTIDA - SEGUNDO TEMPO */
                case 173:
                    if ((score_home_st + score_away_st) >= 6) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO CASA VENCE, FORA MARCA - SEGUNDO TEMPO */
                case 174:
                    if (score_home_st > score_away_st && score_away_st >= 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO FORA VENCE, CASA MARCA - SEGUNDO TEMPO */
                case 175:
                    if (score_away_st > score_home_st && score_home_st >= 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO EMPATE COM GOLS - SEGUNDO TEMPO */
                case 176:
                    if (score_home_st == score_away_st && score_home_st >= 1 && score_away_st >= 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO EMPATE SEM GOLS - SEGUNDO TEMPO */
                case 177:
                    if (score_home_st == score_away_st && score_home_st == 0 && score_away_st == 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO CASA VENCE DE ZERO - SEGUNDO TEMPO */
                case 178:
                    if (score_home_st > score_away_st && score_away_st == 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO FORA VENCE DE ZERO - SEGUNDO TEMPO */
                case 179:
                    if (score_away_st > score_home_st && score_home_st == 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO RESULTADO PAR - SEGUNDO TEMPO */
                case 180:
                    if ((score_home_st + score_away_st) % 2 == 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO RESULTADO ÍMPAR - SEGUNDO TEMPO */
                case 181:
                    if ((score_home_st + score_away_st) % 2 != 0) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 0.5 NA PARTIDA - TEMPO COMPLETO */
                case 182:
                    if ((score_home_90 + score_away_90) < 1) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ABAIXO 1.5 NA PARTIDA - TEMPO COMPLETO */
                case 183:
                    if ((score_home_90 + score_away_90) < 2) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ABAIXO 2.5 NA PARTIDA - TEMPO COMPLETO */
                case 184:
                    if ((score_home_90 + score_away_90) < 3) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ABAIXO 3.5 NA PARTIDA - TEMPO COMPLETO */
                case 185:
                    if ((score_home_90 + score_away_90) < 4) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ABAIXO 4.5 NA PARTIDA - TEMPO COMPLETO */
                case 186:
                    if ((score_home_90 + score_away_90) < 5) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ABAIXO 5.5 NA PARTIDA - TEMPO COMPLETO */
                case 187:
                    if ((score_home_90 + score_away_90) < 6) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÂO ABAIXO 0.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 188:
                    if ((score_home_pt + score_away_pt) < 1) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 1.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 189:
                    if ((score_home_pt + score_away_pt) < 2) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 2.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 190:
                    if ((score_home_pt + score_away_pt) < 3) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 3.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 191:
                    if ((score_home_pt + score_away_pt) < 4) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 4.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 192:
                    if ((score_home_pt + score_away_pt) < 5) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 5.5 NA PARTIDA - PRIMEIRO TEMPO */
                case 193:
                    if ((score_home_pt + score_away_pt) < 6) {
                        situation = hunch.info.period == '1st Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == '1st Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 0.5 NA PARTIDA - SEGUNDO TEMPO */
                case 194:
                    if ((score_home_st + score_away_st) < 1) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 1.5 NA PARTIDA - SEGUNDO TEMPO */
                case 195:
                    if ((score_home_st + score_away_st) < 2) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 2.5 NA PARTIDA - SEGUNDO TEMPO */
                case 196:
                    if ((score_home_st + score_away_st) < 3) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 3.5 NA PARTIDA - SEGUNDO TEMPO */
                case 197:
                    if ((score_home_st + score_away_st) < 4) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 4.5 NA PARTIDA - SEGUNDO TEMPO */
                case 198:
                    if ((score_home_st + score_away_st) < 5) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÂO ABAIXO 5.5 NA PARTIDA - SEGUNDO TEMPO */
                case 199:
                    if ((score_home_st + score_away_st) < 6) {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.winning : 1;
                    } else {
                        situation = hunch.info.period == 'Half Time' ? 0 : hunch.info.period == '2nd Half' ? this.status.losing : 2;
                    }
                break;

                /* OPÇÃO TIME E TEMPO COM MAIS GOL - CASA E 1º TEMPO */
                case 200:
                    if (score_home_pt > score_home_st) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME E TEMPO COM MAIS GOL - CASA E 2º TEMPO */
                case 201:
                    if (score_home_st > score_home_pt) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME E TEMPO COM MAIS GOL - CASA E EMPATE */
                case 202:
                    if (score_home_pt == score_home_st) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME E TEMPO COM MAIS GOL - FORA E 1º TEMPO */
                case 203:
                    if (score_away_pt > score_away_st) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME E TEMPO COM MAIS GOL - FORA E 2º TEMPO */
                case 204:
                    if (score_away_st > score_away_pt) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME E TEMPO COM MAIS GOL - FORA E EMPATE */
                case 205:
                    if (score_away_pt == score_away_st) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME SEM SOFRER GOL - CASA */
                case 206:
                    if (score_away_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME SEM SOFRER GOL - FORA */
                case 207:
                    if (score_home_90 == 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME SOFRE GOL - CASA */
                case 208:
                    if (score_away_90 > 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                /* OPÇÃO TIME SOFRE GOL - FORA */
                case 209:
                    if (score_home_90 > 0) {
                        situation = this.status.winning;
                    } else {
                        situation = this.status.losing;
                    }
                break;

                default:
                    situation = 'Sem informações';
                break;
            }

            return situation;
        },
        getCache() {

            try {

                let hunches = JSON.parse(window.atob(window.localStorage.getItem(`hunches:${this.code}`)));

                for (let key in hunches) {
                    let hunch = hunches[key];

                    if (typeof(this.hunches[hunch.id]) !== 'undefined') {

                        if (this.hunches[hunch.id].situation == 0) {
                            this.hunches[hunch.id] = hunch;
                        }
                    }
                }
            } 
            catch(e) 
            {
                window.sessionStorage.setItem('error', e.message);
            }
        },
        saveCache() {
            window.localStorage.setItem(`hunches:${this.code}`, window.btoa(JSON.stringify(this.hunches)));
        },
        checkSituation() {
            try {
                if (Object.keys(this.hunches).length > 0) {

                    for (let key in this.hunches) {
                        let hunch = this.hunches[key];
                        let event = this.events.find((item) => item.id == hunch.id);

                        if (!event && hunch.live) {

                            hunch.live = false;

                            if (hunch.situation == 'Ganhando') {
                                hunch.situation = 1;
                            } else if (hunch.situation == 'Perdendo') {
                                hunch.situation = 2;
                            }
                        }
                    }
                }
            } 
            catch(e) 
            {
                window.sessionStorage.setItem('error', e.message);
            }
        },
        imageShield(team) {
            try {
                return `${this.removeAccents(team).toLowerCase().trim().replace(/[^a-z-0-9\s]/g, '').replace(/\s/g, '-')}.png`;
            } catch(e) {
                return 'shield.png';
            }
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        orderBySchedule(obj) {
            return Object.values(obj).map(item => {
                return item
            }).sort((a, b) => {

                if (a.schedule > b.schedule) return 1;
                if (a.schedule < b.schedule) return -1;

                return 0;
            });
        },
        showTv(item) {
            if (item.transmissao_active) {
                item.transmissao_active = false;
            } else {
                item.transmissao_active = true;
            }
        }
    },
    filters: {
        formatDateDDMMHHMM(date) {
            return moment(date).tz('America/Recife').format('DD/MM HH:mm');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    beforeMount() {
        this.loading = true;
    },
    created() {
        this.ticketByCode(this.$route.params.code);
    }
}
</script>

<style scoped>
.shield {
    border: 0;
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;
}
.logo {
    width: 100%;
    padding: 1em 0 1.8em 0;
    text-align: center;
}
.widget-field-soccer2 {
    width: 100%;
    overflow: hidden;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/images/ajax-loader.gif');
    z-index: 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 5.6%;
    height: 0!important;
}
.widget-field-soccer2 iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>