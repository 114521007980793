<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div class="w-100" v-show="leagues.length > 0">
                        <div class="row ml-0 mb-3 mr-0">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <div class="btn-group w-100" :class="{'mb-3': platform == 'mobile'}" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-secondary" :class="{'active': status == 0}" @click="status = 0"><i class="fas fa-list mr-2" v-show="platform == 'desktop'"></i>Todas</button>
                                    <button type="button" class="btn btn-secondary" :class="{'active': status == 1}" @click="status = 1"><i class="fas fa-eye mr-2" v-show="platform == 'desktop'"></i>Ativa</button>
                                    <button type="button" class="btn btn-secondary" :class="{'active': status == 2}" @click="status = 2"><i class="fas fa-eye-slash mr-2" v-show="platform == 'desktop'"></i>Desativada</button>
                                </div>
                            </div><!-- /col -->
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Buscar liga...">
                            </div><!-- /col -->
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <button class="btn btn-warning btn-block" @click="save"><i class="fas fa-save mr-2"></i>Salvar alterações</button>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="table-responsive">
                            <table class="table table-striped table-dark table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Liga</th>
                                        <th scope="col">Pré-jogo</th>
                                        <th scope="col">Ao vivo</th>
                                        <th scope="col">Destacar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filteredListLeagues" :key="index">
                                        <td class="align-middle text-nowrap">{{item.nome}}</td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredListLeagues[index].pre_jogo">
                                                <option value="1" :selected="item.pre_jogo == 1">Sim</option>
                                                <option value="2" :selected="item.pre_jogo == 2">Não</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredListLeagues[index].ao_vivo">
                                                <option value="1" :selected="item.ao_vivo == 1">Sim</option>
                                                <option value="2" :selected="item.ao_vivo == 2">Não</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredListLeagues[index].destacar">
                                                <option value="1" :selected="item.destacar == 1">Sim</option>
                                                <option value="2" :selected="item.destacar == 2">Não</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div><!-- /div -->                       
                    </div><!-- /div -->
                </div><!-- /mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            status: 0,
            error: '',
            search: '',
            leagues: [],
            loading: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    components: {
        Navbar
    },
    methods: {
        leaguesLoad() {

            const self = this;

            self.error = '';
            self.loading = true;

            api.get('dashboard/leagues').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.leagues = response.data.leagues.sort((a, b) => {
                            return a.nome.localeCompare(b.nome)
                        });
                    break;
                    case 'nothing_found':
                        self.error = 'Não encontramos nenhuma liga disponível!'
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        save() {

            const self = this;

            Swal.fire({
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                text: 'Salvando alterações...',
                onBeforeOpen: () => {
                  Swal.showLoading()  
                }
            });

            api.post('dashboard/leagues', {
                leagues: self.leagues
            }).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        Swal.fire(
                            'Parabéns!',
                            'As alterações foram salvas com sucesso!',
                            'success'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Falha!',
                            'Não foi posssível salvar as alterações!',
                            'error'
                        );
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'warning'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'warning'
                    );
                }
            });
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        }
    },
    watch: {
        status() {
            this.search = '';
        },
        search() {
            this.status = 0;
        }
    },
    computed: {
        filteredListLeagues() {
            return this.leagues.filter((item) => {
                return this.status != 0 && this.search == '' ? item.pre_jogo == this.status : this.removeAccents(item.nome).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim())
            });
        }
    },
    created() {
        
        this.leaguesLoad();

        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
</style>